import React from 'react';
import './loader.scss'


export const BallBeat = (props) => {
    return (
        <div className="ball-loader" >
            <div className="ball-loader-ball ball1" style={{ backgroundColor: props.color }}></div>
            <div className="ball-loader-ball ball2" style={{ backgroundColor: props.color }}></div>
            <div className="ball-loader-ball ball3" style={{ backgroundColor: props.color }}></div>
        </div>
    )

}