import React, { Component, Fragment, useEffect, useState } from 'react';
import './style.scss'
import axios from 'axios';
import { BallBeat } from '../../utils/Loader/loader';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useNavigate } from 'react-router-dom';


export default function Login() {
    const [defaultShow, setDefaultShow] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResetPassword, setISResetPassword] = useState(false);
    const [resetPasswordComplete, setResetPasswordComplete] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [otpEmail, setOtpEmail] = useState('');
    const [isOtpEmailLoading, setIsOtpEmailLoading] = useState(false);
    const [otpEnable, setOtpEnable] = useState(false);
    const [emailOtpScreen, setEmailOtpScreen] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [logo, setLogo] = useState('');
    const [lang, setLang] = useState('');
    const [langError, setLangError] = useState('');
    const [quoteSelected, setQuoteSelected] = useState('');
    const [succesEnable, setSuccessEnable] = useState(false);
    const [successMessageText, setSuccessMsgText] = useState('');
    const [isOtpResetLoading, setIsOtpResetLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_KEY + 'partner/logo').then((response) => {
            if (response.data.success) {
                setLogo(response.data.logo)
            }
        })
        checkUserAuth()
        if (localStorage.getItem('isLoggedIn') !== "" && localStorage.getItem('isLoggedIn') === 'true') {
            window.location.href = `${localStorage.getItem('domain')}/learn`
        }
        if (localStorage.getItem("loginToken") !== null && localStorage.getItem("loginToken") !== "") {
            if (localStorage.getItem("profileComplete") === "true") {

            }
            else {
                navigate(localStorage.getItem("nextPage"));
            }
        }
        else {
            navigate('/user-login')
        }
        var imagesList = [
            { image: '/images/elite-login1.jpg' },
            { image: '/images/elite-login2.png' },
            { image: '/images/elite-login3.jpg' }
        ];
        let imgSelected = imagesList[Math.floor(Math.random() * imagesList.length)];
        setQuoteSelected(imgSelected);
    }, [])

    const setUserDetails = (data) => {
        if (data.profile_complete) {
            navigate('/feeds')
            localStorage.setItem("profileDetails", JSON.stringify(data));
            localStorage.setItem("isIntroComplete", JSON.stringify(data.introComplete));
            localStorage.setItem("logo", logo);
        }
        else {
            navigate(`/${data.next_step}`)
            localStorage.setItem("profileDetails", JSON.stringify(data));
            localStorage.setItem("logo", logo);
        }
        setIsLoading(false);
    }

    const checkUserAuth = () => {

        if (window.location.href.includes("auto")) {
            setIsLoading(true);
            const url = window.location.href.split('?')[1].split('=')[0]
            const code = window.location.href.split('?')[1].split('=')[1]
            axios.post(process.env.REACT_APP_API_KEY + 'enterprise/admin/auth/autologin', { code: code }).then((response) => {
                if (response.status === 200) {
                    // localStorage.setItem("isIntroComplete", JSON.stringify(result.data.introComplete));
                    localStorage.setItem("loginToken", response.data.token);
                    localStorage.setItem("profileDetails", JSON.stringify(response.data));
                    localStorage.setItem("loginEmail", response.data.email);
                    localStorage.setItem("loginName", response.data.name);
                    localStorage.setItem("profileComplete", response.data.profile_complete)
                    localStorage.setItem("next_step", response.data.next_step)
                    setUserDetails(response.data)

                }
                else {
                    setIsLoading(false);
                    setSuccessEnable(true);
                    setSuccessMsgText(response.data.message);
                }
            })
                .catch(function (error, response) {
                    console.log(error, response);
                })

        }
    }


    const onEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    }


    const loginUser = () => {
        setIsLoading(true);
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email) {
            setEmailError(`Email can't be blank`);
            setIsLoading(false);
            return false;
        }
        else if (!emailrex.test(String(email))) {
            setEmailError("Email is not valid");
            setIsLoading(false);
            return false;
        }
        else if (password === "") {
            setPasswordError('Please enter password');
            setIsLoading(false);
        }
        else if (lang === "") {
            setLangError('Please select app langauge');
            setIsLoading(false);
        }
        else {
            let obj = {
                email, password
            }
            axios.post(process.env.REACT_APP_API_KEY + 'partner/user-login', obj).then((response) => {
                if (response.data.success) {
                    localStorage.setItem('domain', response.data.domain)
                    localStorage.setItem('isLoggedIn', true)
                    if (process.env.NODE_ENV === 'production') {
                        window.location.href = `${response.data.url}&lang=${lang}`
                    }
                    else {
                        window.location.href = `${response.data.url}&lang=${lang}`
                    }
                }
                else {
                    setIsLoading(false);
                    setSuccessEnable(true);
                    setSuccessMsgText(response.data.message);
                }
            })
                .catch((error) => {
                    setIsLoading(false);
                    setSuccessEnable(true);
                    setSuccessMsgText(error?.response?.data?.message);
                })

        }
    }

    const loginOnKeyPress = (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            setIsLoading(true);
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!email) {
                setEmailError(`Email can't be blank`);
                setIsLoading(false);
                return false;
            }
            else if (!emailrex.test(String(email))) {
                setEmailError("Email is not valid");
                setIsLoading(false);
                return false;
            }
            else if (password === "") {
                setPasswordError('Please enter password');
                setIsLoading(false);
            }
            else if (lang === "") {
                setLangError('Please select app langauge');
                setIsLoading(false);
            }
            else {
                let obj = {
                    email, password
                }
                axios.post(process.env.REACT_APP_API_KEY + 'partner/user-login', obj).then((response) => {
                    if (response.data.success) {
                        localStorage.setItem('domain', response.data.domain)
                        localStorage.setItem('isLoggedIn', true)
                        if (process.env.NODE_ENV == 'production') {
                            window.location.href = response.data.url
                        }
                        else {
                            window.location.href = response.data.url

                        }
                    }
                    else {
                        setIsLoading(false);
                        setSuccessEnable(true);
                        setSuccessMsgText(response?.data?.message);
                    }
                })
                    .catch((error) => {
                        setIsLoading(false);
                        setSuccessEnable(true);
                        setSuccessMsgText(error?.response?.data?.message);
                    })
            }
        }
    }

    const resetPassword = () => {
        setISResetPassword(true);
        setEmailError('');
    }

    const handleChange = otp => setOtp(otp);

    const onOtpEmailChange = (e) => {
        setOtpEmail(e.target.value);
        setEmailError('');
    }


    const handleOtpEmail = () => {
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!otpEmail) {
            setEmailError(`Email can't be blank`)
            return false;
        }
        else if (!emailrex.test(String(otpEmail))) {
            setEmailError("Email is not valid")
            return false;
        }
        else {
            setIsOtpEmailLoading(true);
            axios.post(process.env.REACT_APP_API_KEY + 'enterprise/auth/forgot-password', { email: otpEmail }).then((response) => {
                if (response.data.success) {
                    setSuccessEnable(true);
                    setSuccessMsgText(response.data.message);
                    setIsOtpEmailLoading(false);
                    setOtpEnable(true);
                    setEmailOtpScreen(false);
                } else {
                    setIsOtpEmailLoading(false);
                    setSuccessEnable(true);
                    setSuccessMsgText(response.data.message);
                }
            })
                .catch((error) => {
                    setSuccessEnable(true);
                    setSuccessMsgText(error?.response?.data?.message);
                    setIsOtpEmailLoading(false);
                })

        }
    }

    const onOtpEmailChangeKeyPress = (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!otpEmail) {
                setEmailError(`Email can't be blank`)
                return false;
            }
            else if (!emailrex.test(String(otpEmail))) {
                setEmailError("Email is not valid")
                return false;
            }
            else {
                setIsOtpEmailLoading(true);
                axios.post(process.env.REACT_APP_API_KEY + 'enterprise/auth/forgot-password', { email: otpEmail }).then((response) => {
                    if (response.data.success) {
                        setSuccessEnable(true);
                        setSuccessMsgText(response.data.message);
                        setIsOtpEmailLoading(false);
                        setOtpEnable(true);
                        setEmailOtpScreen(false);
                    } else {
                        setIsOtpEmailLoading(false);
                        setSuccessEnable(true);
                        setSuccessMsgText(response.data.message);
                    }
                })
                    .catch((error) => {
                        setSuccessEnable(true);
                        setSuccessMsgText(error?.response?.data?.message);
                        setIsOtpEmailLoading(false);
                    })

            }
        }
    }

    const handleOtpCheck = async () => {
        if (newPassword === "") {
            setSuccessEnable(true);
            setSuccessMsgText("Please enter new password");
        }
        else if (confirmNewPassword === "") {
            setSuccessEnable(true);
            setSuccessMsgText("Please enter confirm password");
        }
        else if (newPassword !== confirmNewPassword) {
            setSuccessEnable(true);
            setSuccessMsgText("Password did not match");
        }
        else if (otp.length < 6) {
            setSuccessEnable(true);
            setSuccessMsgText("Please enter all the code fields");
        }
        else {
            await setIsOtpResetLoading(true)
            axios.post(process.env.REACT_APP_API_KEY + 'enterprise/auth/reset-password', { otp: otp, password: newPassword }).then((response) => {
                if (response.data.success) {
                    setResetPasswordComplete(true);
                    setIsOtpResetLoading(false);
                }
            })
                .catch((error) => {

                    setIsOtpResetLoading(false);
                    setSuccessEnable(true);
                    setSuccessMsgText(error?.response?.data?.message)
                })
        }
    }

    const onKeyPressChangePassword = async (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            if (newPassword === "") {
                setSuccessEnable(true);
                setSuccessMsgText("Please enter new password");
            }
            else if (confirmNewPassword === "") {
                setSuccessEnable(true);
                setSuccessMsgText("Please enter confirm password");
            }
            else if (newPassword !== confirmNewPassword) {
                setSuccessEnable(true);
                setSuccessMsgText("Password did not match");
            }
            else if (otp.length < 6) {
                setSuccessEnable(true);
                setSuccessMsgText("Please enter all the code fields");
            }
            else {
                await setIsOtpResetLoading(true)
                axios.post(process.env.REACT_APP_API_KEY + 'enterprise/auth/reset-password', { otp: otp, password: newPassword }).then((response) => {
                    if (response.data.success) {
                        setResetPasswordComplete(true);
                        setIsOtpResetLoading(false);
                    }
                })
                    .catch((error) => {

                        setIsOtpResetLoading(false);
                        setSuccessEnable(true);
                        setSuccessMsgText(error?.response?.data?.message)
                    })
            }
        }
    }

    const redirectTologin = () => {
        window.location.reload();
    }

    const changeLang = (e) => {
        setLang(e);
        setLangError('');
    }

    return (
        <Fragment>
            <div className='login-module' style={{ background: `url('/images/elite-login2.png') no-repeat center center fixed` }}  >
                {succesEnable &&
                    <ToastContainer
                        className="p-3"
                        position={'top-center'}
                        style={{ zIndex: 1 }}
                    >
                        <Toast bg={'danger'} style={{ borderRadius: 0, }} onClose={() => setSuccessEnable(false)} show={succesEnable} delay={5000} autohide>
                            <Toast.Body style={{ fontSize: 16, padding: 15, color: "#fff" }}>{successMessageText}</Toast.Body>
                        </Toast>
                    </ToastContainer>
                }

                <div className='login-holder'>
                    {
                        defaultShow ?
                            <div className='login-box text-center'>
                                {
                                    !isResetPassword ?
                                        <Fragment>
                                            <img src={logo} alt='' height='52px' />
                                            <p className='text-center'>Login to start learning </p>
                                            <div className="form-group mt-4">
                                                <div className="has-search"  >
                                                    <span className="form-control-feedback" style={{ top: 0 }}>
                                                        <img src="/images/email-icon.svg" alt='' />
                                                    </span>
                                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value={email} onChange={(e) => onEmailChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                                </div>
                                                <small>{emailError}</small>
                                            </div>
                                            <div className="form-group mt-2" >

                                                <div className="has-search"  >
                                                    <span className="form-control-feedback" style={{ top: 0 }}>
                                                        <img src="/images/lock-icon.svg" alt='' />
                                                    </span>
                                                    <input type="password" className="form-control" aria-describedby="emailHelp" placeholder="Password" onChange={(e) => onChangePassword(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                                </div>
                                                <small>{passwordError}</small>
                                            </div>
                                            <div className="form-group text-left mt-2" >
                                                <label>App Language</label>
                                                <div className='elite-lang-select'>
                                                    <div style={{ border: lang === 'en' ? '2px solid #5FBB97' : '2px solid #fff' }} onClick={() => changeLang('en')}>
                                                        English
                                                    </div>
                                                    <div style={{ border: lang === 'hi' ? '2px solid #5FBB97' : '2px solid #fff' }} onClick={() => changeLang('hi')}>
                                                        हिन्दी
                                                    </div>
                                                    <div style={{ border: lang === 'es' ? '2px solid #5FBB97' : '2px solid #fff' }} onClick={() => changeLang('es')}>
                                                        español
                                                    </div>
                                                    <small className='mt-1'>{langError}</small>
                                                </div>
                                            </div>
                                            <div className='form-group mt-3' >
                                                <button className='form-control button-color position-relative' style={{ borderRadius: 2, fontWeight: 'bold', background: '#8b7df2' }} onClick={() => loginUser()}>
                                                    {isLoading ? <BallBeat
                                                        color={'#fff'}
                                                        loading={true}
                                                    /> : "Login"}
                                                </button>
                                            </div>

                                            <h3 className='mt-2 cursor-pointer' onClick={() => resetPassword()}>Forgot Password?</h3>
                                            <h4 style={{ paddingTop: 10, marginBottom: 10 }}>
                                                Powered by <br />
                                                <img src='/images/elite-logo.svg' alt='' />
                                            </h4>
                                            <p className='mt-0'>Download the Elite mobile app</p>
                                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                                <a href='https://play.google.com/store/apps/details?id=com.app.elitelearning112' target='_blank' rel="noreferrer">
                                                    <img alt="" src="/images/app-link.jpg" width="120px" />
                                                </a>
                                                <a href='https://apps.apple.com/in/app/elite-for-workplace/id1543459250' target='_blank' rel="noreferrer">
                                                    <img alt="" src="/images/ios-link.png" width="120px" />
                                                </a>
                                            </div>
                                        </Fragment>
                                        :
                                        <div className="otp-holder">
                                            <h3>Reset your password.</h3>
                                            {
                                                otpEnable === false && emailOtpScreen ?
                                                    <Fragment>
                                                        <p>Please enter your registered Email address.</p>
                                                        <div className="form-group mt-3 w-100">
                                                            <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="Email" onChange={(e) => onOtpEmailChange(e)} onKeyPress={(e) => onOtpEmailChangeKeyPress(e)} value={otpEmail} />
                                                            <small>{emailError}</small>

                                                        </div>
                                                        <button onClick={() => handleOtpEmail()} className="primary-button position-relative mt-2 mb-4">
                                                            {isOtpEmailLoading ? <BallBeat
                                                                color={'#fff'}
                                                                loading={true}
                                                            /> : "CONTINUE"}
                                                        </button>
                                                    </Fragment> :

                                                    resetPasswordComplete === false ?
                                                        <div className="otp-fields d-flex flex-column align-items-center">
                                                            <p>Please enter the code that is sent to your mail.</p>

                                                            <div className="form-group" style={{ width: '100%' }}>
                                                                <input type='text' autoComplete='off' className='form-control' onChange={(e) => handleChange(e.target.value)} style={{ height: 48 }} value={otp} placeholder='Enter otp' />
                                                                <input type="password" placeholder="Enter new password" className="form-control mt-2" aria-describedby="emailHelp" onChange={(e) => setNewPassword(e.target.value)} onKeyPress={(e) => onKeyPressChangePassword(e)} />
                                                                <input type="password" placeholder="Confirm new password" className="form-control mt-2" aria-describedby="emailHelp" onChange={(e) => setConfirmNewPassword(e.target.value)} onKeyPress={(e) => onKeyPressChangePassword(e)} />
                                                            </div>

                                                            <button disabled={isOtpResetLoading === false ? false : true} onClick={() => handleOtpCheck()} className="primary-button mt-2 position-relative">
                                                                {isOtpResetLoading ? <BallBeat
                                                                    color={'#fff'}
                                                                    loading={true}
                                                                /> : "Reset"}
                                                            </button>
                                                        </div> :
                                                        <div className="success-pasword-reset">
                                                            <p>Password has been successfully changed</p>
                                                            <button onClick={() => redirectTologin()} className="primary-button mt-2">Login</button>
                                                        </div>
                                            }
                                        </div>
                                }
                            </div>
                            :
                            <div className="otp-holder">
                                <h3>Set your password.</h3>
                                {
                                    <div className="otp-fields">
                                        <p>Please enter the code that is sent to your mail.</p>
                                        <div className="form-group mt-2">
                                            <input className='form-control' type='text' autoComplete='off' onChange={(e) => handleChange(e.target.value)} style={{ height: 48 }} value={otp} placeholder='Enter otp' />
                                            <input type="password" placeholder="Enter new password" className="form-control mt-2" aria-describedby="emailHelp" onChange={(e) => setNewPassword(e.target.value)} onKeyPress={(e) => onKeyPressChangePassword(e)} />
                                            <input type="password" placeholder="Confirm new password" className="form-control mt-2" aria-describedby="emailHelp" onChange={(e) => setConfirmNewPassword(e.target.value)} onKeyPress={(e) => onKeyPressChangePassword(e)} />
                                        </div>

                                        {/* <button disabled={isOtpResetLoading === false ? false : true} onClick={() => acceptInvitation()} className="primary-button position-relative">
                                            {isOtpResetLoading ? <BallBeat
                                                color={'#fff'}
                                                loading={true}
                                            /> : "Set"}
                                        </button> */}
                                    </div>
                                }

                            </div>
                    }

                </div>
            </div>
        </Fragment>
    )
}


