import React, { useEffect } from 'react';
import './App.scss';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PartnerLoginModule from './Components/Login/login';
import PartnerLoginModule1 from './Components/Login1/login1';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.pathname === '/' && (
      navigate('/user-login')
    )
  }, [])

  return (
    <div className="App">
      <Routes>
        <Route path='/user-login' element={<PartnerLoginModule />} />
        <Route path='/user-login1' element={<PartnerLoginModule1 />} />
      </Routes>
    </div>
  );
}

export default App;
